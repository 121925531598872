<template>
  <div v-loading="loading" class="flex-def flex-zCenter flex-cCenter flex-zTopBottom" style="min-height: 20rem">
    <div  style="width: 40rem;text-align: center;font-weight: 600">
      <div>授权时间</div>
      <div>{{time_start | date}} 至 {{time_end | date}}</div>
    </div>
    <div style="width: 40rem;margin-top: 1rem" class="flex-def flex-zBetween flex-cCenter">
      <div>
        <div>最新版本：{{remote}}</div>
        <div>当前版本：{{local}}</div>
        <div>回退版本：{{back}} <span style="margin-left: 1rem;" class="y-desc">即授权截到期后可用版本</span></div>
      </div>
      <div>
        <template v-if="canUpdate">
          <template v-if="remote !== local">
            <div class="y-desc" style="color: red">更新前做好站点数据备份</div>
            <el-button @click="update" style="margin-top: 1rem;width: 100%" type="primary">立即更新</el-button>
          </template>
          <div v-else style="color: #4ca2ff;font-weight: 600" class="y-pointer">当前已是最新版本</div>
        </template>
        <div v-else style="color: red;font-weight: 600" class="y-pointer">授权已到期无法更新</div>
      </div>
    </div>
    <upgrade-log></upgrade-log>
  </div>
</template>

<script>
import UpgradeLog from "@/view/founder/upgradeLog";
export default {
  name: "sys",
  components: {UpgradeLog},
  data(){
    return{
      local:"",
      remote:"",
      back:"",
      time_end:0,
      time_start:0,

      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  computed:{
    canUpdate(){
      return this.time_end > new Date().getTime();
    },
  },
  methods:{
    load(){
      this.loading = true;
      this.$u.api.founder.versionRemote().then(res=>{
        this.loading = false;
        this.local = res.local;
        this.remote = res.remote;
        this.back = res.back;
        this.time_start = res.time_start;
        this.time_end = res.time_end;
      })
    },
    update(){
      this.$loading({text:"系统升级中请稍候..."})
      this.$u.api.founder.versionUpdate().then(()=>{
        setTimeout(()=>{
          this.$loading().close()
          location.reload();
        },3000)
      })
    },
  }
}
</script>

<style scoped>

</style>